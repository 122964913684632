import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Observable, combineLatestWith, map } from 'rxjs';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';

import { StorageService } from '@shared/core/services/storage.service';
import { VisuallyImpairedButtonComponent } from '@shared/components/visually-impaired-button/visually-impaired-button.component';
import { ChangeCityService } from '@shared/services/changeCity.service';
import { VisuallyImpairedService } from '@shared/services/visually-impaired.service';
import { YmGoalHeader } from '@shared/modules/header/enums/ym-goal-header.enum';
import { YmReachGoalDirective } from '@shared/directives/ym-reach-goal.directive';
import { PhoneNumbers } from '@shared/types/phone-numbers';
import { ReportErrorModalService } from '../report-error-modal/services/report-error-modal.service';
import { WriteToHelixModalService } from '../write-to-helix-modal/services/write-to-helix-modal.service';
import { BurgerDatasourceService } from './services/burger.datasource.service';

@Component({
  selector: 'app-burger',
  templateUrl: './burger.component.html',
  styleUrls: ['./burger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NzCollapseModule,
    VisuallyImpairedButtonComponent,
    YmReachGoalDirective,
  ],
  providers: [BurgerDatasourceService],
})
export class BurgerComponent {
  constructor(
    private datasource: BurgerDatasourceService,
    public storage: StorageService,
    private changeCityService: ChangeCityService,
    public visuallyImpaired: VisuallyImpairedService,
    public reportErrorModalService: ReportErrorModalService,
    public writeToHelixModalService: WriteToHelixModalService
  ) {}

  @Output() closeBurger = new EventEmitter<void>();

  phoneNumbers$: Observable<PhoneNumbers> = this.datasource.isHelixLite$.pipe(
    combineLatestWith(this.storage.companyPhone$, this.storage.federalPhone$),
    map(([isHelixLite, company, federal]) =>
      isHelixLite
        ? PhoneNumbers.HelixLitePhone
        : {
            company,
            federal,
          }
    )
  );

  readonly itemsBurger$ = this.datasource.itemsBurger$;
  readonly itemsCollapse: string[] = this.datasource.itemsCollapse;
  readonly itemsAdditionalInfo$ = this.datasource.itemsAdditionalInfo$;
  readonly itemsAboutCompany$ = this.datasource.itemsAboutCompany$;
  protected readonly YmGoalHeader = YmGoalHeader;

  openCityModal(): void {
    this.changeCityService.openCityModal();
  }

  switchOnVI(): void {
    this.visuallyImpaired.switchOnVI();
    this.emitCloseBurger();
  }

  emitCloseBurger(): void {
    this.closeBurger.emit();
  }
}
