export enum HelixRoutes {
  Pages = 'site/page',
  Cart = 'preorder',
  CartEmpty = 'preorder/empty',
  Catalog = 'catalog',
  CatalogItem = 'catalog/item',
  // TODO - https://youtrack.helix.ru/issue/HELIXRU-4025
  CatalogOnco = 'catalog/analizy-dlya-onkodiagnostiki',
  /**
   * @deprecated
   */
  HelixExpress = 'helix-express',
  AboutHelixLite = 'helix-lite',
  Centers = 'centers',
  Center = 'center',
  Job = 'job',
  MobileService = 'ms',
  Promotions = 'promotions',
  PromotionsSelect = 'promotions/select',
  Loyalty = 'loyalty',
  Onco = 'onco',
  CancelAppointment = 'appointment/cancel',
  News = 'feed/archive',
  NewsSelect = 'feed/select',
  Publications = 'publishing',
  ShortCancelAppointment = 'ca',
  Technologies = 'technologies',
  Category = 'category',
  Site = 'site',
  Sitemap = 'sitemap',
  Helixbook = 'kb',
  HelixbookItem = 'kb/item',
  PractitionerAppointment = 'doctors',
  Error = 'error',
  Home = '',
}

export enum WebApiCart {
  Info = '/api/cart/info',
  Centers = '/api/cart/centers',
  Appointment = '/api/appointment',
}

export enum WebApiCities {
  City = '/api/cities',
}

export enum WebApiCenters {
  Metros = '/api/centers/metros',
  CentersCount = '/api/centers/count',
  Center = '/api/centers',
  AllCenters = '/api/centers/all',
  Tags = '/api/centers/tags',
  Promotions = '/api/centers/promotions',
  CountByCities = '/api/centers/all/countByCity',
}

export enum WebApiHome {
  Banners = '/api/content/main/banners',
}

export enum WebApiCatalog {
  PopularItems = '/api/catalog/popular-items',
}

export enum WebApiNews {
  SearchDates = '/api/news/dates/search',
  SearchNews = '/api/news/search',
  GetNewsItem = '/api/news',
  GetNameTag = '/api/news/tags',
}

export enum WebApiPublications {
  Categories = '/api/publications/categories',
  Tags = '/api/publications/tags',
  Search = '/api/publications/search',
}

export enum WebApiLabomat {
  Labomats = '/api/labomats',
}

export enum WebApiB2B {
  LabPass = '/api/b2b/labpassFeedback',
}

export enum WebApiFeedback {
  Practitioner = '/api/feedback/practitioner',
}
