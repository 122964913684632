import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, combineLatestWith, map, takeUntil } from 'rxjs';

import { PhoneNumbers } from '@shared/types/phone-numbers';
import { StorageService } from '@shared/core/services/storage.service';
import { AddCartItemService } from '@shared/modules/add-cart-item/services/add-cart-item.service';
import { HelixRoutes } from '@shared/enums/routes';
import { ChangeCityService } from '@shared/services/changeCity.service';
import { VisuallyImpairedService } from '@shared/services/visually-impaired.service';
import { PlatformService } from '@shared/services/platform.service';
import { YmGoalHeader } from '@shared/modules/header/enums/ym-goal-header.enum';
import { ReportErrorModalService } from '@shared/components/report-error-modal/services/report-error-modal.service';
import { WriteToHelixModalService } from '@shared/components/write-to-helix-modal/services/write-to-helix-modal.service';
import { YmService } from '@shared/services/yandex-metrica/ym.service';
import { NavigationMenuService } from '@shared/services/navigation-menu.service';
import { YmGoalAppointment } from '@shared/services/yandex-metrica/enums/appointment.goals';
import { isAbsoluteLink } from '@shared/utils/isAbsoluteLink';
import { HeaderRepositoryService } from '../../services/header-repository.service';
import { NavItem } from '../../types/header.types';
import { NavMenuSubItem } from '../../types/nav-menu-item.types';

import categoriesDropdownBackgroundImage from '!!file-loader!./assets/categories-dropdown-bg-image.png';

@Component({
  selector: 'app-helix-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    public repository: HeaderRepositoryService,
    public storage: StorageService,
    public changeCityService: ChangeCityService,
    public visuallyImpaired: VisuallyImpairedService,
    public platform: PlatformService,
    public reportErrorModalService: ReportErrorModalService,
    public writeToHelixModalService: WriteToHelixModalService,
    private router: Router,
    private addCartItemService: AddCartItemService,
    private ymService: YmService,
    private navigationMenuService: NavigationMenuService
  ) {}

  @HostListener('window:resize', ['$event'])
  checkIfShouldShowMobileSearch(): void {
    if (this.platform.isBrowser) {
      this.shouldShowMobileSearch = window?.innerWidth < this.mobileWidthPx;
    }
  }

  /** Должны ли показать версию поиска для адаптива или обычную. */
  shouldShowMobileSearch = false;

  readonly categoriesDropdownBackgroundImageUrl =
    categoriesDropdownBackgroundImage;

  phoneNumbers$: Observable<PhoneNumbers> =
    this.navigationMenuService.isHelixLiteAvailable$.pipe(
      combineLatestWith(this.storage.companyPhone$, this.storage.federalPhone$),
      map(([isHelixLite, company, federal]) =>
        isHelixLite
          ? PhoneNumbers.HelixLitePhone
          : {
              company,
              federal,
            }
      )
    );

  clientNavItems$: Observable<NavItem[]> =
    this.navigationMenuService.appointmentUrl$.pipe(
      combineLatestWith(
        this.navigationMenuService.catalogCategories$,
        this.navigationMenuService.isHelixLiteAvailable$
      ),
      map(([appointmentUrl, categories, isHelixLite]): NavItem[] => {
        const menuItems: NavItem[] = [
          {
            link: `/${HelixRoutes.Catalog}`,
            name: 'Анализы',
            testId: 'header-nav-catalog',
            vpWidth: 92,
            minHeightPx: 422,
            itemColumns: 3,
            items: categories?.map((c) => ({
              title: c.description ?? '',
              link: `/${HelixRoutes.Catalog}/${c.urlPart}`,
              testId: `header-nav-catalog-item-${c.id}`,
            })),
            moreInfo: {
              title: 'Все анализы',
              subtitle: 'Более 3600 анализов',
              cornerImageUrl: this.categoriesDropdownBackgroundImageUrl,
              moreButtonText: 'В каталог',
              moreButtonUrl: '/catalog/vse-analizy',
            },
          },
        ];

        if (isHelixLite) {
          menuItems.push({
            link: `/${HelixRoutes.AboutHelixLite}`,
            name: 'О Helix Lite',
            testId: 'header-nav-about-helix-lite',
          });
        } else {
          menuItems.push(
            {
              link: appointmentUrl,
              name: 'Запись к врачам',
              testId: 'header-nav-practitioner-appointment',
              ymGoalName: appointmentUrl.includes(
                HelixRoutes.PractitionerAppointment
              )
                ? YmGoalAppointment.HeaderNavigationDoctorAppointmentPageOpen
                : YmGoalAppointment.HeaderNavigationDoctorAppointmentCatalogOpen,
            },
            {
              link: `/${HelixRoutes.MobileService}`,
              name: 'Выезд на дом',
              testId: 'header-nav-ms',
            }
          );
        }

        menuItems.push({
          link: `/${HelixRoutes.Promotions}`,
          name: 'Скидки и акции',
          testId: 'header-nav-promo',
        });

        menuItems.push(
          {
            link: `/${HelixRoutes.Centers}`,
            name: isHelixLite ? 'Адреса' : 'Адреса Центров',
            testId: 'header-nav-centers',
          },
          {
            link: `/${HelixRoutes.Helixbook}`,
            name: 'Helixbook',
            testId: 'header-nav-kb',
          },
          {
            link: '',
            name: 'Еще',
            testId: 'header-nav-additional',
            vpWidth: 34,
            alignRight: true,
            itemColumns: 2,
            items: this.getMoreSubItems(isHelixLite),
          }
        );
        return menuItems;
      })
    );

  aboutCompanyNavItems: NavItem[] = [
    { link: '/site/page/107', name: 'Контакты', testId: 'header-nav-contacts' },
    {
      link: '/site/page/116',
      name: 'Контроль качества',
      testId: 'header-nav-quality-control',
    },
    {
      link: '/site/page/46',
      name: 'Лицензии и сертификаты',
      testId: 'header-nav-certificates',
    },
    {
      link: '/publishing',
      name: 'Пресса о нас',
      testId: 'header-nav-press-about-us',
    },
    { link: '/job', name: 'Вакансии', testId: 'header-nav-job' },
  ];

  headerNavItems$: Observable<NavItem[]> = this.storage.configuration$.pipe(
    map(({ hosts }) => {
      return [
        {
          link: hosts.doctorAccount,
          name: 'Врачам',
          ymGoalName: YmGoalHeader.DoctorsButtonClick,
          testId: 'header-nav-for-doctors',
        },
        {
          link: hosts.b2b,
          name: 'Организациям',
          ymGoalName: YmGoalHeader.OrganizationsButtonClick,
          testId: 'header-nav-for-organizations',
        },
        {
          link: 'https://fr.helix.ru/',
          name: 'Франчайзинг',
          ymGoalName: YmGoalHeader.FranchisingButtonClick,
          testId: 'header-nav-franchising',
        },
      ];
    })
  );

  loginLink$: Observable<string> = this.storage.configuration$.pipe(
    map(({ hosts }) => hosts.login)
  );

  personalAccount$: Observable<string> = this.storage.configuration$.pipe(
    map(({ hosts }) => hosts.personalAccount)
  );

  protected readonly YmGoalHeader = YmGoalHeader;

  protected isAbsoluteLink = isAbsoluteLink;

  /** Ширина окна, меньше которой показываем мобильную версию. */
  private readonly mobileWidthPx = 576;

  private ngUnsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.checkIfShouldShowMobileSearch();
  }

  openCityModal(): void {
    this.changeCityService.openCityModal();
  }

  switchOnVI(): void {
    this.visuallyImpaired.switchOnVI();
  }

  updateHxidsAndGoToCart(): void {
    this.addCartItemService
      .getCartHxids()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((hxids) =>
        this.router.navigate([
          HelixRoutes.Cart,
          hxids.length ? 'step1' : 'empty',
        ])
      );
  }

  ymReachGoal(name: string | undefined): void {
    if (!name) return;
    this.ymService.reachGoal(name);
  }

  private getMoreSubItems(isHelixLite: boolean): NavMenuSubItem[] {
    const moreSubItems: NavMenuSubItem[] = [];
    moreSubItems.push(
      {
        click: () => this.writeToHelixModalService.openIfns(),
        title: 'Заказ справки для налоговой',
        testId: 'order-help',
      },
      {
        click: () => this.writeToHelixModalService.openDefault(),
        title: 'Написать в Хеликс',
        testId: 'write-to-helix',
      }
    );
    if (!isHelixLite) {
      moreSubItems.push({
        link: '/site/page/52',
        title: 'Прием по ДМС',
        testId: 'header-nav-info-dms',
      });
    }
    moreSubItems.push(
      {
        click: () => this.reportErrorModalService.open(),
        title: 'Сообщить об ошибке на сайте',
        testId: 'send-message-error',
      },
      {
        link: '/site/page/86',
        title: 'Как получить результат',
        testId: 'header-nav-info-howtoget',
      }
    );
    if (!isHelixLite) {
      moreSubItems.push({
        link: '/site/page/53',
        title: 'Предварительный заказ',
        testId: 'header-nav-info-preorder',
      });
    }
    moreSubItems.push(
      {
        link: '/site/page/65',
        title: 'О Личном кабинете',
        testId: 'header-nav-info-lkk',
      },
      {
        link: '/site/page/59',
        title: 'Подготовка к анализам',
        testId: 'header-nav-info-prepare',
      }
    );
    return moreSubItems;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
