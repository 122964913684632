import { Injectable } from '@angular/core';
import { NavItem } from '../interfaces/burger.interfaces';
import { HelixRoutes } from '@shared/enums/routes';
import { HelixRoutePage } from '@shared/enums/helix-route-pages';
import { NavigationMenuService } from '@shared/services/navigation-menu.service';
import { map, Observable } from 'rxjs';

@Injectable()
export class BurgerDatasourceService {
  isHelixLite$ = this.navigationMenu.isHelixLiteAvailable$;

  itemsBurger$: Observable<NavItem[]> = this.isHelixLite$.pipe(
    map((isHelixLite) => {
      const itemsBurger = [
        {
          link: `/${HelixRoutes.Promotions}`,
          title: 'Скидки и акции',
          testId: 'adaptive-header-nav-promo',
        },
        {
          link: `/${HelixRoutes.Centers}`,
          title: isHelixLite ? 'Адреса' : 'Адреса Центров Хеликс',
          testId: 'adaptive-header-nav-centers',
        },
        {
          link: `/${HelixRoutes.Helixbook}`,
          title: 'Helixbook',
          testId: 'adaptive-header-nav-kb',
        },
      ];
      return isHelixLite
        ? [
            {
              link: `/${HelixRoutes.AboutHelixLite}`,
              title: 'О Helix Lite',
              testId: 'adaptive-header-nav-about-helix-lite',
            },
            ...itemsBurger,
          ]
        : itemsBurger;
    })
  );

  readonly itemsCollapse = ['Сервисы', 'Еще'];

  itemsAdditionalInfo$: Observable<NavItem[]> = this.isHelixLite$.pipe(
    map((isHelixLite) => {
      const itemsAdditionalInfo = [
        {
          link: HelixRoutePage.HowGetResult,
          title: 'Как получить результат',
          testId: 'header-nav-info-howtoget',
        },
      ];
      if (!isHelixLite) {
        itemsAdditionalInfo.push({
          link: HelixRoutePage.PreorderInfo,
          title: 'Предварительный заказ',
          testId: 'header-nav-info-preorder',
        });
      }
      itemsAdditionalInfo.push(
        {
          link: HelixRoutePage.LkkInfo,
          title: 'О Личном кабинете',
          testId: 'header-nav-info-lkk',
        },
        {
          link: HelixRoutePage.PrepareInfo,
          title: 'Подготовка к анализам',
          testId: 'header-nav-info-prepare',
        }
      );
      if (!isHelixLite) {
        itemsAdditionalInfo.push({
          link: HelixRoutePage.DmsInfo,
          title: 'Прием по ДМС',
          testId: 'header-nav-info-dms',
        });
      }
      return itemsAdditionalInfo;
    })
  );

  itemsAboutCompany$: Observable<NavItem[]> = this.isHelixLite$.pipe(
    map((isHelixLite) => {
      const itemsAboutCompany = [
        {
          link: HelixRoutePage.Contacts,
          title: 'Контакты',
          testId: 'adaptive-header-nav-contacts',
        },
        {
          link: HelixRoutePage.QualityControl,
          title: 'Контроль качества',
          testId: 'adaptive-header-nav-quality-control',
        },
        {
          link: HelixRoutePage.Licenses,
          title: 'Лицензии и сертификаты',
          testId: 'adaptive-header-nav-certificates',
        },
        {
          link: `/${HelixRoutes.Publications}`,
          title: 'Пресса о нас',
          testId: 'adaptive-header-nav-press-about-us',
        },
      ];
      if (!isHelixLite) {
        itemsAboutCompany.push({
          link: `/${HelixRoutes.Job}`,
          title: 'Вакансии',
          testId: 'adaptive-header-nav-job',
        });
      }
      return itemsAboutCompany;
    })
  );

  constructor(private navigationMenu: NavigationMenuService) {}
}
