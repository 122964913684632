<div class="mobile-app-banner grid mt-24 mb-12"
  *ngIf="!(navigationMenu.isHelixLiteAvailable$ | async) && (shouldShow$ | async)" 
  [@visibilityAnimation]>
  <div class="grid-item grid">
    <div class="grid-item grid grid-column column-1">
      <div class="grid-item my-8 typography typography-medium white title-font-size">
        В приложении удобнее
      </div>
      <div class="grid-item grid justify-content-between align-items-center">
        <div class="grid-item">
          <button class="button button-subtle typography typography-medium typography-headline title-font-size"
          (click)="open()">
          Открыть
        </button>
        </div>
      </div>
    </div>
    <div class="grid-item grid-4 hidden-very-small column-2">
      <div class="image-container mt-8">
        <img [src]="banner" />
      </div>
    </div>
  </div>
  <div class="grid-item">
    <span class="icon icon-cross-1 white title-font-size" (click)="close()"></span>
  </div>
</div>
