import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { VisuallyImpairedService } from './visually-impaired.service';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { PlatformService } from './platform.service';

interface PropertyStore {
  getPropertyValue(property: string): string;
}

class StubPropertyStore implements PropertyStore {
  getPropertyValue(property: string): string {
    return '';
  }
}

@Injectable()
export class CssVariablesService {
  private propertyStore = this.platform.isServer
    ? new StubPropertyStore()
    : undefined;
  private get rootCssVariablesStore(): PropertyStore {
    if (!this.propertyStore) {
      this.propertyStore = getComputedStyle(document.body);
    }
    return this.propertyStore;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private platform: PlatformService,
    private viService: VisuallyImpairedService
  ) {}

  getRootCssVariableAccordingToCurrentVI(
    variableName: string,
    fallbackValue = ''
  ): Observable<string> {
    return this.viService.stateVI$.pipe(
      distinctUntilChanged(),
      map(() => this.getRootCssVariable(variableName, fallbackValue))
    );
  }

  private getRootCssVariable(variableName: string, fallbackValue = ''): string {
    const variableValue =
      this.rootCssVariablesStore.getPropertyValue(variableName);
    return !!variableValue ? variableValue : fallbackValue;
  }
}
