<app-visually-impaired></app-visually-impaired>
<app-helix-header></app-helix-header>
<div class="typography">
  <router-outlet></router-outlet>
</div>
<app-helix-footer
  [phoneNumbers]="phoneNumbers$ | async"
></app-helix-footer>

<app-mobile-bottom-navigation></app-mobile-bottom-navigation>
