export class Navigation {
  constructor(public title: string, public items: Link[] | null) {}
}

export class Link {
  constructor(
    public link: string,
    public title: string,
    public hideForHelixLite?: boolean,
    public isHrefLink?: boolean
  ) {}
}

export class SocialNetwork {
  constructor(
    public link: string,
    public icon: string,
    public ariaLabel: string
  ) {}
}
