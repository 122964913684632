import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { map } from 'rxjs';

import { Link, Navigation } from '@shared/components/footer/types/menu.model';
import { NavigationMenuService } from '@shared/services/navigation-menu.service';

@Component({
  selector: 'app-navigation-list',
  templateUrl: './navigation-list.component.html',
  styleUrls: ['./navigation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class NavigationListComponent {
  @Input() list: Navigation | undefined;

  navigationItems$ = this.navigationMenu.isHelixLiteAvailable$.pipe(
    map((isHelixLite) => {
      return !this.list?.items
        ? []
        : isHelixLite
        ? this.list.items.filter((item) => !item.hideForHelixLite)
        : this.list.items;
    })
  );

  constructor(private navigationMenu: NavigationMenuService) {}

  trackBy(index: number, item: Link): string {
    return item.title;
  }
}
