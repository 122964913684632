import { ModalErrorType } from 'src/app/shared/enums/modal-error-type';
import { Router } from '@angular/router';
import {
  switchMap,
  filter,
  tap,
  map,
  catchError,
  distinctUntilChanged,
  takeUntil,
  first,
} from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { Injectable, OnDestroy, Type } from '@angular/core';
import { CartItemResultStatus } from 'src/app/shared/types/types';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { ModalSize } from 'src/app/shared/enums/modal-sizes';
import { WarningModalComponent } from 'src/app/shared/components/warning-modal/warning-modal.component';
import { HelixRoutes } from 'src/app/shared/enums/routes';
import { ItemInComplexModalComponent } from '../components/item-in-complex-modal/item-in-complex-modal.component';
import { ItemDuplicateModalComponent } from '../components/item-duplicate-modal/item-duplicate-modal.component';
import { AddToCartMessages } from '../const/messages';
import { StorageService } from '@shared/core/services/storage.service';
import { AddItemsToCartResponse, CartClient } from '@medindex-webapi';
import { YmService } from '@shared/services/yandex-metrica/ym.service';

@Injectable()
export class AddCartItemService implements OnDestroy {
  private _hxids$ = new BehaviorSubject<string[]>([]);
  hxids$ = this._hxids$.asObservable();

  private _isInitialized$ = new BehaviorSubject<boolean>(false);
  isInitialized$ = this._isInitialized$.pipe(distinctUntilChanged());

  private readonly CartItemResultStatus = CartItemResultStatus;
  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private storage: StorageService,
    private modalService: ModalService,
    private router: Router,
    private cartClient: CartClient,
    private ymService: YmService
  ) {
    this.storage.cityId$
      .pipe(
        distinctUntilChanged(),
        switchMap(() => this.getCartHxids()),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  addItemWithConfirmation(
    hxid: string,
    ymGoalName?: string
  ): Observable<string[]> {
    return this.storage.cityId$.pipe(
      filter((cityId): cityId is number => !!cityId),
      switchMap((cityId) =>
        this.cartClient.addItemToCartWithConfirmation({
          hxid: hxid,
          cityId: cityId,
        })
      ),
      tap((response) => {
        switch (response.cartItemResultStatus) {
          case this.CartItemResultStatus.Added: {
            this.ymReachGoal(ymGoalName);
            this._createModal(
              WarningModalComponent,
              {
                content: AddToCartMessages.AddSuccess,
                errorType: ModalErrorType.Success,
              },
              ModalSize.Message,
              2000
            );
            break;
          }

          case this.CartItemResultStatus.Duplicate: {
            this._createModal(
              ItemDuplicateModalComponent,
              { hxid, ymGoalName },
              ModalSize.Message
            );
            break;
          }

          case this.CartItemResultStatus.InComplex: {
            this._createModal(
              ItemInComplexModalComponent,
              {
                hxid,
                message: response.message,
                ymGoalName,
              },
              ModalSize.Message
            );
            break;
          }

          case this.CartItemResultStatus.RedirectToInformationPage: {
            this.router.navigate([`/${HelixRoutes.CatalogItem}/${hxid}`]);
            break;
          }

          default:
          case this.CartItemResultStatus.Error: {
            this._createModal(
              WarningModalComponent,
              {
                content: AddToCartMessages.ErrorMessage,
                title: AddToCartMessages.ErrorTitle,
              },
              ModalSize.Error
            );
            break;
          }
        }
      }),
      switchMap((response) =>
        response.cartItemResultStatus === this.CartItemResultStatus.Added
          ? this.getCartHxids()
          : this._hxids$
      ),
      catchError((error) => {
        this._createModal(
          WarningModalComponent,
          {
            content: AddToCartMessages.ErrorMessage,
            title: AddToCartMessages.ErrorTitle,
          },
          ModalSize.Error
        );
        return throwError(() => error);
      })
    );
  }

  addCartItem(hxid: string, ymGoalName: string): Observable<string[]> {
    return this.storage.cityId$.pipe(
      filter((cityId) => !!cityId),
      switchMap((cityId) =>
        this.cartClient.addItemToCart({
          hxid: hxid,
          cityId: cityId,
        })
      ),
      tap((): void => {
        this.ymReachGoal(ymGoalName);
        this._createModal(
          WarningModalComponent,
          {
            content: AddToCartMessages.AddSuccess,
            errorType: ModalErrorType.Success,
          },
          ModalSize.Message,
          2000
        );
      }),
      switchMap(() => this.getCartHxids()),
      catchError((error) => {
        this._createModal(
          WarningModalComponent,
          {
            content: AddToCartMessages.ErrorMessage,
            title: AddToCartMessages.ErrorTitle,
          },
          ModalSize.Error
        );
        return throwError(() => error);
      })
    );
  }

  addCartItems(
    hxids: string[],
    source: string,
    ymGoalName: string
  ): Observable<string[] | null> {
    return this.storage.cityId$.pipe(
      filter((cityId) => !!cityId),
      first(),
      switchMap((cityId) =>
        this.cartClient.addItemsToCart({
          hxids,
          cityId,
          source,
        })
      ),
      tap((): void => {
        this.ymReachGoal(ymGoalName, { lead_source: source });
        this.getCartHxids().pipe(first()).subscribe();
      }),
      map(
        (addItemsResponse: AddItemsToCartResponse) =>
          addItemsResponse.notAddedHxids
      )
    );
  }

  getCartHxids(): Observable<string[]> {
    return this.cartClient.getCartItemsHxids().pipe(
      catchError((error) => {
        this._createModal(
          WarningModalComponent,
          {
            content: AddToCartMessages.ErrorMessage,
            title: AddToCartMessages.ErrorTitle,
          },
          ModalSize.Error
        );
        return throwError(() => error);
      }),
      tap((hxids) => {
        this._hxids$.next(hxids);
        this._isInitialized$.next(true);
      })
    );
  }

  private _createModal(
    component: Type<any>,
    params: object,
    width: ModalSize,
    duration: number | undefined = undefined
  ) {
    this.modalService.destroyAll();
    this.modalService.create(
      {
        nzContent: component,
        nzComponentParams: params,
        nzStyle: { width },
        nzFooter: null,
      },
      duration
    );
  }

  private ymReachGoal(
    name: string | undefined,
    options?: Record<string, string | undefined>
  ): void {
    if (name) this.ymService.reachGoal(name, options);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
